import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RouterConfig from './RouterConfig';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware,compose  } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import reducer from './reducers/rootReducer';
// import * as serviceWorker from './serviceWorker';

const middleware = [ thunk ];
let store;
  if (process.env.NODE_ENV === 'production') {
    middleware.push(createLogger());
     store = createStore(reducer, compose(applyMiddleware(...middleware)))
  }else{
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
     store = createStore(reducer, composeEnhancers(
     applyMiddleware(...middleware)))
}

ReactDOM.render(
    <Provider store={store}>
       <RouterConfig />
      </Provider>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
