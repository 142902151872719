import React, { Component } from 'react';
import { HashRouter, Route, Switch,BrowserRouter, Redirect } from 'react-router-dom';


// import { renderRoutes } from 'react-router-config';
import './App.scss';

import { createBrowserHistory } from 'history'
const history = createBrowserHistory();

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/ReactViews/Account/Login'));
const ForgotPassword = React.lazy(() => import('./views/ReactViews/Account/ForgotPassword'));
const SetForgotPassword = React.lazy(() => import('./views/ReactViews/Account/SetForgotPassword'));
const Register = React.lazy(() => import('./views/Pages/Register'));

var isLogin = false;
let userData = JSON.parse(localStorage.getItem('userData'));
if (userData) {
  isLogin = true;
}

class RouterConfig extends Component {

  render() {
    return (
      <BrowserRouter history={history} basename={"/"}> 
          <React.Suspense fallback={loading()}>
            <Switch>

            {!isLogin &&
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />}
              
              <Route exact path="/forgot-password" name="Forgot Pwd Page" render={props => <ForgotPassword {...props}/>} />

              <Route exact path="/reset-password-:token" name="Forgot Pwd Page" render={props => <SetForgotPassword {...props}/>} />

              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
             
              {isLogin &&
                    <Route path="/" name="Meetings" render={props => <DefaultLayout {...props}/>} />
              }

               {!isLogin &&
               <Redirect from="/" to="/login" />}

              {isLogin &&
               <Redirect from="/" to="/meetings" />}

            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default RouterConfig;
